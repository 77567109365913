<div class="archived-checkbox">
  <mat-checkbox
    (change)="showArchived.set($event.checked)"
    [checked]="showArchived()"
    class="archived-checkbox">
    Archivierte Daten anzeigen
  </mat-checkbox>
</div>
<app-entity-editor
  (delete)="deleteAssignmentIndicator($event)"
  (edit)="editAssignmentIndicator($event)"
  (new)="newAssignmentIndicator($event)"
  (restore)="restoreAssignmentIndicator($event)"
  (selectEntry)="selectAssignmentIndicator($event)"
  [entries]="assignmentIndicatorsBasedata()"
  [highlightEntry]="null"
  label="Projektkennzeichen"
></app-entity-editor>

