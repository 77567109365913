import {ActionReducerMap} from '@ngrx/store'

import * as fromAssignmentIndicatorStore from './assignment-indicators-store/reducer'
import * as fromAssignmentIndicatorStoreEffects from './assignment-indicators-store/effects'


export interface AppState {
  assignmentIndicatorsStore: fromAssignmentIndicatorStore.State
}

export const appReducer: ActionReducerMap<AppState> = {
  assignmentIndicatorsStore: fromAssignmentIndicatorStore.reducer,
}

export const storeEffects = [
  fromAssignmentIndicatorStoreEffects.Effects
]
