
import {createReducer, on} from '@ngrx/store'
import {AssignmentIndicator, Updates} from './actions'
import {storeListUpdate} from '../reducerUtils'


// Declaration of storage state
export interface State {
  assignmentIndicators: AssignmentIndicator[],    // Has a list of spells
}

// Create an initial state
const initState: State = {
  assignmentIndicators : []
}

export const reducer = createReducer(
  initState,

  on(Updates, (state, {upserts, deletes}) => {
    return {
      ...state,
      assignmentIndicators: storeListUpdate(state.assignmentIndicators, upserts, deletes)
    }
  }),

)
