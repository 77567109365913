import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasedataEntry} from '../entity-editor/entity-editor.component';
import {Subscription} from 'rxjs';
import {CustomerEntity, CustomerService} from '../../services/repository/customer.service';
import {AssignmentEntity, AssignmentService} from '../../services/repository/assignment.service';
import {TopicEntity, TopicService} from '../../services/repository/topic.service';
import {MatDialog} from '@angular/material/dialog';
import {CustomerEditDialogComponent} from '../customer-edit-dialog/customer-edit-dialog.component';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
  ConfirmDialogType
} from '../../shared-components/confirm-dialog/confirm-dialog.component';
import {AssignmentEditDialogComponent} from '../assignment-edit-dialog/assignment-edit-dialog.component';
import {TopicEditDialogComponent} from '../topic-edit-dialog/topic-edit-dialog.component';
import {RebookTopicDialogComponent} from '../rebook-dialog/topic-rebook-dialog.component';
import {UserService} from '../../services/repository/user.service';

@Component({
  selector: 'app-topics-editor',
  templateUrl: './topics-editor.component.html',
  styleUrls: ['./topics-editor.component.scss']
})
export class TopicsEditorComponent implements OnInit, OnDestroy {

  customerEntries: BasedataEntry[] = [];
  allCustomers: CustomerEntity[] = [];
  assignmentEntries: BasedataEntry[] = [];
  allAssignments: AssignmentEntity[] = [];
  topicEntries: BasedataEntry[] = [];
  allTopics: TopicEntity[] = [];
  selectedCustomer: BasedataEntry = null;
  selectedAssignment: BasedataEntry = null;
  showArchived = false;
  private customerServiceSubscription: Subscription;
  private assignmentServiceSubscription: Subscription;
  private topicServiceSubscription: Subscription;

  constructor(private customerService: CustomerService,
              private assignmentService: AssignmentService,
              private topicService: TopicService,
              private userService: UserService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.customerService.requestAll();
    this.assignmentService.requestAll();
    this.topicService.requestAll();
    this.userService.requestAssignmentSupervisors();

    this.customerServiceSubscription = this.customerService.all$.subscribe(list => {
      this.allCustomers = list;
      this.updateCustomerData();
    });

    this.assignmentServiceSubscription = this.assignmentService.all$.subscribe(list => {
      this.allAssignments = list;
      this.updateAssignmentData();
    });

    this.topicServiceSubscription = this.topicService.all$.subscribe(list => {
      this.allTopics = list;
      this.updateTopicData();
    });
  }

  ngOnDestroy(): void {
    this.customerServiceSubscription.unsubscribe();
    this.assignmentServiceSubscription.unsubscribe();
    this.topicServiceSubscription.unsubscribe();
  }

  newCustomer(): void {
    this.dialog.open(CustomerEditDialogComponent, {
      width: '500px',
      data: {id: -1, addCustomer: true}
    });
  }

  deleteCustomer(entry: BasedataEntry): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        text: getArchivingText('den Kunden "' + entry.label + '"'),
        type: ConfirmDialogType.INFO
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe(okPressed => {
      if (!okPressed) {
        return;
      }
      this.customerService.get(entry.id).subscribe(customer => {
        this.customerService.delete(customer);
        this.topicEntries = [];
        this.assignmentEntries = [];
      });
    });
  }

  editCustomer(entry: BasedataEntry): void {
    this.dialog.open(CustomerEditDialogComponent, {
      width: '500px',
      data: {id: entry.id, addCustomer: false}
    });
  }

  selectCustomer(entry: BasedataEntry): void {
    this.selectedCustomer = entry;
    this.updateAssignmentData();
    this.topicEntries = [];
    this.selectedAssignment = null;
  }

  newAssignment(): void {
    if (this.selectedCustomer != null) {
      this.dialog.open(AssignmentEditDialogComponent, {
        width: '500px',
        data: {addAssignment: true, relatedFK: this.selectedCustomer.id}
      });
    }
  }

  deleteAssignment(entry: BasedataEntry): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        text: getArchivingText('den Auftrag "' + entry.label + '"'),
        type: ConfirmDialogType.INFO
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe(okPressed => {
      if (!okPressed) {
        return;
      }
      this.assignmentService.get(entry.id).subscribe(assignment => {
        this.assignmentService.delete(assignment);
        this.topicEntries = [];
      });
    });
  }

  editAssignment(entry: BasedataEntry): void {
    this.dialog.open(AssignmentEditDialogComponent, {
      width: '500px',
      data: {id: entry.id, addAssignment: false}
    });
  }

  selectAssignment(entry: BasedataEntry): void {
    this.selectedAssignment = entry;
    this.updateTopicData();
  }

  newTopic(): void {
    if (this.selectedAssignment == null || this.selectedCustomer == null) {
      return;
    }

    this.dialog.open(TopicEditDialogComponent, {
      width: '500px',
      data: {id: -1, addTopic: true, assignmentId: this.selectedAssignment.id}
    });
  }

  deleteTopic(entry: BasedataEntry): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        text: getArchivingText('das Thema "' + entry.label + '"'),
        type: ConfirmDialogType.INFO
      } as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe(okPressed => {
      if (!okPressed) {
        return;
      }
      this.topicService.get(entry.id).subscribe(topic => {
        this.topicService.delete(topic);
      });
    });
  }

  editTopic(entry: BasedataEntry): void {
    if (this.selectedAssignment == null) {
      return;
    }

    this.dialog.open(TopicEditDialogComponent, {
      width: '500px',
      data: {id: entry.id, addTopic: false}
    });
  }


  rebookTopic(entry: BasedataEntry): void {
    this.dialog.open(RebookTopicDialogComponent, {
      width: '1027px',
      data: {id: entry.id},
      autoFocus: false
    });
  }

  updateShowArchived(): void {
    this.showArchived = !this.showArchived;
    this.updateCustomerData();
    this.updateAssignmentData();
    this.updateTopicData();
  }

  restoreTopic(entry: BasedataEntry): void {
    this.topicService.get(entry.id).subscribe(topic => {
      this.assignmentService.get(topic.assignmentFK).subscribe(assignment => {
        if (assignment.archived) {
        } else {
          this.topicService.restore(topic);
        }
      });
    });
  }

  restoreAssignment(entry: BasedataEntry): void {
    this.assignmentService.get(entry.id).subscribe(assignment => {
      this.customerService.get(assignment.customFK).subscribe(customer => {
        if (customer.archived) {
        } else {
          this.assignmentService.restore(assignment);
          this.topicEntries = [];
          this.selectedAssignment = null;
        }
      });
    });
  }

  restoreCustomer(entry: BasedataEntry): void {
    this.customerService.get(entry.id).subscribe(customer => {
      this.customerService.restore(customer);
      this.topicEntries = [];
      this.assignmentEntries = [];
      this.selectedCustomer = null;
    });
  }

  updateCustomerData(): void {
    if (this.allCustomers != null) {
      this.customerEntries =
        sortEntries(
          this.allCustomers
            .filter(customer => this.showArchived || !customer.archived)
            .map(customer => {
              return {
                id: customer.id,
                label: customer.name,
                archived: customer.archived
              } as BasedataEntry;
            })
        );
    }
  }

  updateAssignmentData(): void {
    if (this.selectedCustomer != null) {
      this.assignmentEntries =
        sortEntries(
          this.allAssignments
            .filter(assignment => !assignment.archived || this.showArchived)
            .filter(assignment => assignment.customFK === this.selectedCustomer.id)
            .map(assignment => {
              return {
                id: assignment.id,
                label: assignment.name,
                archived: assignment.archived
              } as BasedataEntry;
            })
        );
    } else {
      this.assignmentEntries = [];
    }
  }

  updateTopicData(): void {
    if (this.selectedAssignment != null) {
      this.topicEntries =
        sortEntries(
          this.allTopics
            .filter(topic => topic.archivedDate == null || this.showArchived)
            .filter(topic => topic.assignmentFK === this.selectedAssignment.id)
            .map(topic => {
              return {
                id: topic.id,
                label: topic.name,
                archived: topic.archivedDate != null
              } as BasedataEntry;
            })
        );
    } else {
      this.topicEntries = [];
    }
  }

}

function getArchivingText(input: string): string {
  return `Wollen Sie ${input} wirklich archivieren?`;
}

function sortEntries(entries: BasedataEntry[]): BasedataEntry[] {
  return [...entries].sort((a, b) => {
    if (a.archived && !b.archived) {
      return 1;
    } else if (!a.archived && b.archived) {
      return -1;
    } else if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
      return -1;
    }
    return 1;
  });
}

export interface EditDialogData {
  id: number;
  addCustomer: boolean;
  addAssignment: boolean;
  relatedFK: number;
}

export interface TopicEditDialogData {
  id: number;
  addTopic: boolean;
  assignmentId: number;
}
