import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {BasedataComponent} from './basedata.component'
import {RouterModule} from '@angular/router'
import {EntityEditorComponent} from './entity-editor/entity-editor.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import {MatButtonModule} from '@angular/material/button'
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatDialogModule} from '@angular/material/dialog'
import {MatFormFieldModule} from '@angular/material/form-field'
import {MatIconModule} from '@angular/material/icon'
import {MatInputModule} from '@angular/material/input'
import {MatListModule} from '@angular/material/list'
import {MatSelectModule} from '@angular/material/select'
import {CustomerEditDialogComponent} from './customer-edit-dialog/customer-edit-dialog.component'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {AssignmentEditDialogComponent} from './assignment-edit-dialog/assignment-edit-dialog.component'
import {TopicEditDialogComponent} from './topic-edit-dialog/topic-edit-dialog.component'
import {TimeEntryTypeEditorComponent} from './time-entry-type-editor/time-entry-type-editor.component'
import {TimeEntryTypeDialogComponent} from './time-entry-type-dialog/time-entry-type-dialog.component'
import {SharedComponentsModule} from '../shared-components/shared-components.module'
import {AbsenceEntryTypeEditorComponent} from './absence-entry-type-editor/absence-entry-type-editor.component'
import {AbsenceEntryTypeEditorDialogComponent} from './absence-entry-type-editor-dialog/absence-entry-type-editor-dialog.component'
import {UsersEditorComponent} from './users-editor/users-editor.component'
import {TopicsEditorComponent} from './topics-editor.component.ts/topics-editor.component'
import {TimeTypesEditorComponent} from './time-types-editor/time-types-editor.component'
import {UsersAnonDialogComponent} from './users-anon-dialog/users-anon-dialog.component'
import {UsersEditDialogComponent} from './users-edit-dialog/users-edit-dialog.component'
import {FormatDatePipe, GetUserNameFromUserListPipe, GetUserNamePipe, IsBaseDataTypePipe, StatusDisplayNamePipe} from './basedata.pipe'
import {DragDropModule} from '@angular/cdk/drag-drop'
import {ErrorTooltipComponent} from './error-tooltip/error-tooltip.component'
import {RebookTopicDialogComponent} from './rebook-dialog/topic-rebook-dialog.component'
import {RebookTopicConfirmDialogComponent} from './rebook-dialog/rebook.confirm-dialog/topic-rebook-confirm-dialog.component'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatNativeDateModule} from '@angular/material/core'
import {OverlayModule} from '@angular/cdk/overlay'
import {AddWorkplaceDialogComponent} from './add-workplace-dialog/add-workplace-dialog.component'
import {AssignmentIndicatorsComponent} from './assignment-indicators/assignment-indicators.component'
import {
  AssignmentIndicatorEditDialogComponent
} from './assignment-indicators/assignment-indicator-edit-dialog/assignment-indicator-edit-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: '',
        component: BasedataComponent
      }
    ]),
    MatDialogModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    SharedComponentsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule,
  ],
  providers: [
    MatDatepickerModule
  ],
  declarations: [
    BasedataComponent,
    EntityEditorComponent,
    CustomerEditDialogComponent,
    AssignmentEditDialogComponent,
    TopicEditDialogComponent,
    AssignmentIndicatorEditDialogComponent,
    TimeEntryTypeEditorComponent,
    TimeEntryTypeDialogComponent,
    TimeTypesEditorComponent,
    UsersEditorComponent,
    AbsenceEntryTypeEditorComponent,
    AbsenceEntryTypeEditorDialogComponent,
    UsersAnonDialogComponent,
    TopicsEditorComponent,
    TimeTypesEditorComponent,
    UsersEditDialogComponent,
    FormatDatePipe,
    IsBaseDataTypePipe,
    GetUserNamePipe,
    GetUserNameFromUserListPipe,
    StatusDisplayNamePipe,
    RebookTopicDialogComponent,
    RebookTopicConfirmDialogComponent,
    ErrorTooltipComponent,
    AddWorkplaceDialogComponent,
    AssignmentIndicatorsComponent
  ],
  exports: [
    UsersEditDialogComponent,
    EntityEditorComponent
  ]
})

export class BasedataModule {
}

