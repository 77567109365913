import {createAction, props} from '@ngrx/store'


export let featureNAME = 'assignment-indicators'
export let className = featureNAME
  .toLowerCase()
  .replace(/(^|_)./g, s => s.charAt(s.length - 1).toUpperCase())


export interface AssignmentIndicator {
  id: number,
  name: string,
  description: string,
  created: number,
  archived: boolean
}

// Create actions constants
export const ActionTypes = {
  POST_REQUEST      : `${featureNAME}::POST_REQUEST`,
  PULL_ALL_REQUEST  : `${featureNAME}::PULL_ALL_REQUEST`,
  PULL_REQUEST      : `${featureNAME}::PULL_REQUEST`,     // To trigger a pull event to the Server
  UPDATES           : `${featureNAME}::UPDATES`,
} as const


export const PullAllRequest = createAction(
  ActionTypes.PULL_ALL_REQUEST
)

export const PullRequest = createAction(
  ActionTypes.PULL_REQUEST,
  props<{id: number}>()
)

export const PostRequest = createAction(
  ActionTypes.POST_REQUEST,
  props<{
    deletes: number[],
    upserts: AssignmentIndicator[],
  }>()
)

export const Updates = createAction(
  ActionTypes.UPDATES,
  props<{upserts: AssignmentIndicator[], deletes: number[]}>()
)
