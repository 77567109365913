import {Component, computed, Inject, OnDestroy, OnInit, signal} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {AssignmentEntity, AssignmentService} from '../../services/repository/assignment.service'
import {EditDialogData} from '../topics-editor.component.ts/topics-editor.component'
import {
  ASSIGNMENTS_CONTRACT_NUMBER_MAX_LENGTH,
  ASSIGNMENTS_DESCRIPTION_MAX_LENGTH,
  ASSIGNMENTS_NAME_MAX_LENGTH,
  ServerConstantsService
} from '../../services/server-constants.service'
import {Subject, takeUntil} from 'rxjs'
import {hasPermission, Permission, UserService} from '../../services/repository/user.service'
import {SelectOption} from '../../shared-components/select/select.component'
import {selectAllAssignmentIndicators} from '../../store/assignment-indicators-store/selectors'
import {Store} from '@ngrx/store'

const DEFAULT_INPUT_LENGTH = 200

@Component({
  selector: 'app-assignment-edit-dialog',
  templateUrl: './assignment-edit-dialog.component.html',
  styleUrls: ['./assignment-edit-dialog.component.scss']
})
export class AssignmentEditDialogComponent implements OnInit, OnDestroy {

  assignment = signal<AssignmentEntity>({
    name: '',
    description: '',
    contractNumber: '',
    customFK: this.data.relatedFK,
    assignmentIndicatorFK: null,
    archived: false,
  })
  private _assignmentIndicators = this.store.selectSignal(selectAllAssignmentIndicators)
  assigmentIndicatorError = computed(() =>
    this._assignmentIndicators()
      .find(indicator => indicator.id === this.assignment().assignmentIndicatorFK)?.archived
  )

  assignmentNameMaxLength: number
  assignmentDescriptionMaxLength: number
  assignmentContractNumberMaxLength: number
  allSupervisors: SelectOption<number>[] = [
    {
      displayName: '--- keinen auswählen ---',
      value: null
    }
  ]

  allAssignmentIndicators: SelectOption<number>[] = [
    {
      displayName: '--- keinen auswählen ---',
      value: null
    }
  ]


  private destroy$ = new Subject<void>()

  constructor(private dialogRef: MatDialogRef<AssignmentEditDialogComponent>,
              private assignmentService: AssignmentService,
              private serverConstantsService: ServerConstantsService,
              @Inject(MAT_DIALOG_DATA) private data: EditDialogData,
              private userService: UserService,
              private store: Store) {
  }

  ngOnInit(): void {
    this.allAssignmentIndicators.push(...this._assignmentIndicators()
      .filter(indicator => !indicator.archived)
      .map(indicator => ({
        displayName: indicator.name,
        value: indicator.id
      })))

    if (!this.data.addAssignment) {
      this.assignmentService.get(this.data.id).subscribe(assignment => this.assignment.set(assignment))
    }


    this.serverConstantsService.onConstantsExtracted$
      .pipe(takeUntil(this.destroy$))
      .subscribe(constants => {
        this.assignmentNameMaxLength = constants[ASSIGNMENTS_NAME_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
        this.assignmentDescriptionMaxLength = constants[ASSIGNMENTS_DESCRIPTION_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
        this.assignmentContractNumberMaxLength = constants[ASSIGNMENTS_CONTRACT_NUMBER_MAX_LENGTH] || DEFAULT_INPUT_LENGTH
      })

    this.userService.allAssignmentSupervisor$
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: supervisors => {
          this.allSupervisors.push(...supervisors.map(supervisor => ({
            displayName: `${supervisor.lastName}, ${supervisor.firstName}`,
            value: supervisor.id
          })))
        },
        error: error => console.error('Error fetching supervisors', error)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  selectEqualFunction(self: number, other: number): boolean {
    return (self != null && other != null) && (self === other)
  }

  canEditResponsibleSupervisor(): boolean {
    return hasPermission(this.userService.me, Permission.ProcessAssignmentSupervisorPermission)
  }

  cancel(): void {
    this.dialogRef.close()
  }

  changeValue<T, K extends keyof T>(obj: T, property: K, value: T[K]): void {
    this.assignment.set
    ({
      ...this.assignment(),
      [property]: value
    })
  }

  confirm(): void {
    this.dialogRef.close()

    if (this.data.addAssignment) {
      this.assignmentService.create(this.assignment())
    } else {
      this.assignmentService.update(this.assignment())
    }
  }
}
