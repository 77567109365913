import {State} from './reducer'
import {createFeatureSelector, createSelector} from '@ngrx/store'


export const selectAssignmentIndicatorsState = createFeatureSelector<State>('assignmentIndicatorsStore')

// Selektor für die Liste aller Kreaturen
export const selectAllAssignmentIndicators = createSelector(
  selectAssignmentIndicatorsState,
  (state: State) => state.assignmentIndicators
)
