import {Component, Inject, input, signal} from '@angular/core'
import {AssignmentIndicator, PostRequest} from '../../../store/assignment-indicators-store/actions'
import {Store} from '@ngrx/store'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

@Component({
  selector: 'app-assignment-indicator-edit-dialog',
  templateUrl: './assignment-indicator-edit-dialog.component.html',
  styleUrl: './assignment-indicator-edit-dialog.component.scss'
})
export class AssignmentIndicatorEditDialogComponent {

  assignmentIndicator = signal<AssignmentIndicator>(
    {
      id: null,
      name: '',
      description: '',
      created: null,
      archived: false,
    }
  )

  constructor(private store: Store,
              private dialogRef: MatDialogRef<AssignmentIndicatorEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AssignmentIndicator
  )
  {
    if (data){
      this.assignmentIndicator.set(data)
    }
  }

    confirm(): void{
      this.dialogRef.close()

      this.store.dispatch(PostRequest(
      {
        upserts: [this.assignmentIndicator()],
        deletes: [],
      },
    ))
  }

  updateName(newName: string): void {
    this.assignmentIndicator.set({
      ...this.assignmentIndicator(),
      name: newName,
    })
  }

  updateDescription(newDescription: string): void {
    this.assignmentIndicator.set({
      ...this.assignmentIndicator(),
      description: newDescription,
    })
  }

  cancel(): void{
    this.dialogRef.close()
  }

}
