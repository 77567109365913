import {Component, computed, OnInit, signal, Signal} from '@angular/core'
import {Store} from '@ngrx/store'
import {AssignmentIndicator, PostRequest, PullAllRequest} from '../../store/assignment-indicators-store/actions'
import {selectAllAssignmentIndicators} from '../../store/assignment-indicators-store/selectors'
import {BasedataEntry} from '../entity-editor/entity-editor.component'
import {
  AssignmentIndicatorEditDialogComponent
} from './assignment-indicator-edit-dialog/assignment-indicator-edit-dialog.component'
import {MatDialog} from '@angular/material/dialog'


@Component({
  selector: 'app-assignment-indicators',
  templateUrl: './assignment-indicators.component.html',
  styleUrl: './assignment-indicators.component.scss'
})
export class AssignmentIndicatorsComponent {

  private _assignmentIndicators = this.store.selectSignal(selectAllAssignmentIndicators)
  protected showArchived = signal(false)
  assignmentIndicatorsMap: Signal<Map<number, AssignmentIndicator>> = computed(() => {
    return new Map(this._assignmentIndicators().map(item => [item.id, item]))
  })

  assignmentIndicatorsBasedata: Signal<BasedataEntry[]> = computed(() => {
    // Hier warten wir auf die Änderung der Daten aus dem Store
    return this._assignmentIndicators()
      ?.filter(item => this.showArchived() ? true : !item.archived)
      ?.map(item => ({
        id: item.id,
        label: item.name,  // Verwende `name` als Label
        archived: item.archived,   // Setze `archived` auf einen Standardwert
      })) || []
  })

  constructor(private store: Store,
              private dialog: MatDialog) {
    this.showArchived()
  }

  deleteAssignmentIndicator(entry: any): void {
    const update: AssignmentIndicator = {...this.assignmentIndicatorsMap().get(entry.id), archived: true}
    this.store.dispatch(PostRequest(
      {
        upserts: [update],
        deletes: [],
      },
    ))
  }

  editAssignmentIndicator(entry: any): void {
    this.dialog.open(
      AssignmentIndicatorEditDialogComponent,
      {
        width: '500px',
        data: this.assignmentIndicatorsMap().get(entry.id),
      }
    )
  }

  newAssignmentIndicator(_: any): void {
    this.dialog.open(AssignmentIndicatorEditDialogComponent,
      {
        width: '500px',
        data: null
      })
  }

  restoreAssignmentIndicator(entry: BasedataEntry): void {
    const update: AssignmentIndicator = {...this.assignmentIndicatorsMap().get(entry.id), archived: false}
    this.store.dispatch(PostRequest(
      {
        upserts: [update],
        deletes: [],
      },
    ))
  }

  selectAssignmentIndicator(entry: any): void {
  }

}
