

export function storeListUpdate(origin: any[], upserts: any[], deletes: any[]): any[] {


  const updatedIds = new Set<number>()
  const lookupDeletes = new Set(deletes)
  const lookupUpserts = new Map(upserts.map(u => [u.id, u]))

  const filteredClassFeatures = origin.filter(s => !lookupDeletes.has(s.id))

  const updatedClassFeatures = filteredClassFeatures.map(s => {

    const updatedFeature = lookupUpserts.get(s.id)
    if (updatedFeature) {
      updatedIds.add(s.id)
      return updatedFeature
    }
    return s
  })

  // 6. Features einfügen, die in `upserts` sind, aber noch nicht geupdatet wurden
  const newUpserts = upserts.filter(u => !updatedIds.has(u.id))

  return [...updatedClassFeatures, ...newUpserts]
}
