import {Component, OnInit} from '@angular/core'
import {hasPermissions, Permission, UserService} from '../services/repository/user.service'
import {PullAllRequest} from '../store/assignment-indicators-store/actions'
import {Store} from '@ngrx/store'

@Component({
  selector: 'app-basedata',
  templateUrl: './basedata.component.html',
  styleUrls: ['./basedata.component.scss']
})
export class BasedataComponent implements OnInit {

  readonly TOPICS = 'Themen'
  readonly TIME_TYPES = 'Zeiten'
  readonly USERS = 'Mitarbeiter'
  readonly ASSIGNMENT_INDICATORS = 'PKZ'

  content: string = this.TOPICS
  buttons = []

  constructor(
    private userService: UserService,
    private store: Store
  ) {
    this.store.dispatch(PullAllRequest())
  }

  ngOnInit(): void {
    this.buildToggleButtons()
  }

  onToggle(toggleText: string): void {
    this.content = toggleText
  }

  buildToggleButtons(): void {
    this.buttons = []

    const me = this.userService.me

    if (hasPermissions(me, [Permission.ProcessTopicsPermisson])) {
      this.buttons.push({text: this.TOPICS, icon: ''})
    }

    if (hasPermissions(me, [Permission.ProcessOtherUsersPermission])) {
      this.buttons.push({text: this.USERS, icon: ''})
    }

    if (hasPermissions(me, [Permission.ProcessTimeTypesPermisson])) {
      this.buttons.push({text: this.TIME_TYPES, icon: ''})
    }

    if (hasPermissions(me, [Permission.ProcessTimeTypesPermisson])) {
      this.buttons.push({text: this.ASSIGNMENT_INDICATORS, icon: ''})
    }

    if (this.buttons.length === 1) {
      this.buttons = []
    }
  }
}

