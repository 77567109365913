import {Injectable} from '@angular/core'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {map, mergeMap} from 'rxjs/operators'
import {AssignmentIndicator, featureNAME, PostRequest, PullAllRequest, Updates} from './actions'
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../environments/environment'


@Injectable()
export class Effects {


  pullAllRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(PullAllRequest),
      mergeMap(() =>
        this.httpClient.get(this.repoURL).pipe(
          map((data) =>
            Updates(data as { upserts: AssignmentIndicator[], deletes: number[] })
          )
        )
      ))
  )

  postRequest$ = createEffect(
    () => this.actions$.pipe(
      ofType(PostRequest),
      mergeMap(({type, ...data}) => {
          return this.httpClient.post(this.repoURL, data).pipe(
            map((response) =>
              Updates(response as { upserts: AssignmentIndicator[], deletes: number[] })
            )
          )
        }
      ))
  )


  private readonly repoURL: string

  constructor(
    private actions$: Actions,
    private httpClient: HttpClient,
  ) {
    this.repoURL = `${environment.serverUrl}/${featureNAME}`
  }
}
