<div *ngIf="assignment != null">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Name</mat-label>
      <input
        (change)="changeValue(AssignmentEntity, 'name', $event.target.value)"
        [value]="assignment().name"
        [maxLength]="assignmentNameMaxLength"
        matInput
        placeholder="Name">
      <mat-hint align="end">{{ assignment().name.length }}/{{ assignmentNameMaxLength }}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Beschreibung</mat-label>
      <input
        [value]="assignment().description"
        (change)="changeValue(AssignmentEntity, 'description', $event.target.value)"
        [maxLength]="assignmentDescriptionMaxLength"
        matInput
        placeholder="Beschreibung"
      >
      <mat-hint align="end">{{ assignment().description.length }}/{{ assignmentDescriptionMaxLength }}</mat-hint>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="full-width">
      <mat-label>Vertragsnummer</mat-label>
      <input
        [value]="assignment().contractNumber"
        (change)="changeValue(AssignmentEntity, 'contractNumber', $event.target.value)"
        [maxLength]="assignmentContractNumberMaxLength"
        matInput
        placeholder="Vertragsnummer"
      >
      <mat-hint align="end">{{ assignment().contractNumber.length }}/{{ assignmentContractNumberMaxLength }}</mat-hint>
    </mat-form-field>
  </div>

  <div class="full-width supervisor-row">
    <div class="field-name">Verantwortlicher</div>
    <div class="field-value">
      <app-select
        *ngIf="canEditResponsibleSupervisor(); else readonlySupervisorTemp"
        (selectedOptionChange)="changeValue(AssignmentEntity, 'responsibleSupervisorFK', $event)"
        [selectedOption]="assignment().responsibleSupervisorFK"
        [options]="allSupervisors"
        [compareSelectedWithOptions]="selectEqualFunction"
        placeholder="Verantwortlicher"
      />
      <ng-template #readonlySupervisorTemp>
        <div class="row-name">{{ assignment().responsibleSupervisorFK | getUserNameFromUserList: allSupervisors }}</div>
      </ng-template>
    </div>
  </div>

  <div class="full-width supervisor-row">
    <div class="field-name">Projektkennzeichen</div>
    <div class="field-value">
      <app-select
        *ngIf="canEditResponsibleSupervisor(); else readonlySupervisorTemp" #TODO
        (selectedOptionChange)="changeValue(AssignmentEntity, 'assignmentIndicatorFK', $event)"
        [selectedOption]="assignment().assignmentIndicatorFK"
        [options]="allAssignmentIndicators"
        [compareSelectedWithOptions]="selectEqualFunction"
        [errorMessage]="''"
        placeholder="Projektkennzeichen"
      />
      <div class="error-assignment-indicator-not-found" *ngIf="assigmentIndicatorError()">
        Das PKZ konnte nicht gefunden werden. Eventuell wurde es archiviert. Bitte wähle ein neues aus.
      </div>
    </div>
  </div>

  <div class="button-bar">
    <button (click)="confirm()" color="primary" mat-raised-button>OK</button>
    <button (click)="cancel()" color="warn" mat-raised-button>Abbrechen</button>
  </div>
</div>
